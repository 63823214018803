import PagedFilterBase from "./PagedFilterBase"

class ZoneFilter extends PagedFilterBase {
    CountryId?: string;
    FranchiseId?: string

    constructor(init?: Partial<ZoneFilter>) {
        super()
        if (init) Object.assign(this, init);
    }
}

export default ZoneFilter
