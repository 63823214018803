
import { Table, TableData, TableHeader, TableRow } from '@/components/table'
import { Vue, Component } from 'vue-property-decorator'
import { Services } from 'tradingmate-modules/src/services'
import ZoneFilter from 'tradingmate-modules/src/filters/ZoneFilter'
import AdminZoneModel from 'tradingmate-modules/src/models/api/admin/zones/AdminZoneModel'

@Component({
  components: {
    Table,
    TableRow,
    TableData,
    TableHeader
  }
})
export default class AdminZoneIndex extends Vue {
  private filter: ZoneFilter = new ZoneFilter()
  private zones: AdminZoneModel[] | null = null
  private loading = false

  mounted (): void {
    this.getZones()
  }

  getZones (): void {
    this.loading = true
    Services.API.Admin.Zones.GetZones(this.filter)
      .then((returnModels) => { this.zones = returnModels })
      .finally(() => { this.loading = false })
  }

  addZone (): void {
    this.$router.push('/admin/zone/create')
  }
}

